<template>
  <v-flex>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        v-for="(item, index) in inputCard"
        :key="index"
      >
        <v-card class="mx-auto" max-width="400">
          <v-card-title primary-title>
            <v-card-title>{{ item.title }}</v-card-title>
            <v-icon>{{ item.icon }}</v-icon>
          </v-card-title>
          <v-card-actions>
            <v-col>
              <v-select
                v-model="item.projectSelectedId"
                :items="projects"
                item-text="name"
                item-value="id"
                @change="onFileUpload(item.projectSelectedId, index)"
                :rules="[(v) => !!v || 'Escolha o projeto']"
                label="Projetos"
                required
              ></v-select>
            </v-col>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              :disabled="item.disabled"
              icon
              @click="downloadFile(index, item)"
            >
              <v-icon>file_download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar color="success" v-model="showSnackBar" bottom
      >CSV enviado com sucesso!</v-snackbar
    >
    <v-snackbar color="error" v-model="showSnackBarErrors" bottom
      >Ouve um erro durante a operação!!!</v-snackbar
    >
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex';
import { saveAs } from 'file-saver';
import api from '../../../plugins/api/ApiService';

export default {
  name: 'ExportPrivatePerson',

  data: () => ({
    messageLoading: 'enviando...',
    showSnackBar: false,
    showSnackBarErrors: false,
    file: null,
    projects: [],
    projectNames: [],
    inputCard: [
      {
        title: 'CSV - Projetos',
        icon: 'file_download',
        label: 'Upload CSV Enriquecido',
        disabled: true,
        isLoading: false,
      }
    ]
  }),

  methods: {
    ...mapActions(['setTitle']),

    onFileUpload(projectSelectedId, index) {
      if (projectSelectedId) this.inputCard[index].disabled = false;
    },

    async downloadFile(index, { projectSelectedId }) {
      const { name } = this.projects.find((project) => project.id === projectSelectedId);

      await api.exportSheetId(projectSelectedId)
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: 'text/csv;charset=utf-8'
          });
          saveAs(blob, `${name}`);
        }).catch((err) => {
          this.showSnackBarErrors = true;
          console.error(err);
        });
    },

    async getProjects() {
      await api.getProjects().then((res) => {
        this.projects = res.data.filter((project) => {
          return project.project_type === 'legal_entity';
        });
      });
    }
  },

  async created() {
    this.getProjects();
    this.setTitle({ title: 'CSV - Pessoa Jurídica' });
  }
};
</script>
